export const isValidEmail = (Email) =>
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    Email
  );

export const isValidURL = (str) => {
  const re =
    /(((^https?)|(^ftp)):\/\/([\-\w]+\.)+\w{2,3}(\/[%\-\w]+(\.\w{2,})?)*(([\w\-\.\?\\\/+@&#;`~=%!]*)(\.\w{2,})?)*\/?)/i;
  return re.test(str);
};

export const isValidPhoneNumber = (number) => {
  const regex =
    /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/;
  return regex.test(number);
};
export const isPasswordValid = (pass) => {
  const regex = /(?=.*\d)(?=.*[a-zA-Z]).{6,}/;
  return regex.test(pass);
};
export function isAlpha(value) {
  return /^[a-zA-Z\s]{0,50}$/.test(value);
}
export function isNumeric(value) {
  return /^[0-9]*$/.test(value);
}
export function isAlphaNumeric(value) {
  return /^[a-zA-Z0-9 _-]{1,25}$/.test(value);
}
export const isValidCnic = (value) =>
  /^[0-9+]{5}[0-9+]{7}[0-9]{1}$/.test(value);
  
 
